import {
    useMediaQuery,
    Box,
    SimpleGrid,
    Image,
    List,
    ListItem,
    Heading,
    Text,
    Flex,
    Icon,
    ListIcon,
    Link
} from '@chakra-ui/react';
import SectionHeading from '../cards/SectionHeading';
import IPMRLaptop from '../../assets/images/ipmr-laptop.png';
import Button from '../buttons/Simple';
import { ChevronRightIcon } from '@chakra-ui/icons';

const points = [
    "Detailed Reports",
    "Realtime Status",
    "Factory Electricity UpDown Report",
    "Offline Capability",
    "Automatic Report Mailing",
    "Preventive Maintenance"
]

const BulletPoint = () => {
    return <Icon scale={0.5} viewBox='0 0 200 200' color='#ffa500'>
        <path
            fill='currentColor'
            d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
        />
    </Icon>
}

const LatestProduct = () => {

    const [isLargerThan1300] = useMediaQuery('(min-width: 1300px)');

    return (
        <Box backgroundColor={"#ececec"} p={isLargerThan1300 ? "50px 15vh" : "50px 0"}>
            <SectionHeading
                heading="Our Latest Product"
                subHeading="new release 2022"
                details={"We here at Kunjisoft are always working on new products and services to offer you. Check out our latest product below."}
            />
            <SimpleGrid minChildWidth="300px" mt="80px" spacing={8} w="100%">
                <Box
                    backgroundColor={"#FFFFFF"}
                    borderRadius="20px"
                    p="30px"
                    h="450px"
                    minH="fit-content"
                >
                    <Heading
                        fontFamily="Poppins"
                        fontSize="20px"
                    >industrialpmr – Industrial Production Monitoring & Reporting</Heading>
                    <Text
                        m="20px 0"
                    >what industrialpmr offers:</Text>
                    <List
                        mb="20px"
                    >
                        {
                            points.map(point => (
                                <ListItem m="10px 0">
                                    <Text>
                                        <ListIcon as={BulletPoint} />
                                        {" "}{point}
                                    </Text>
                                </ListItem>
                            ))
                        }
                    </List>
                    <Link
                        href={"https://industrialpmr.com"}
                        w="fit-content"
                        target="_blank"
                        _hover={{ textDecoration: 'none' }}>
                        <Button
                            text={"Learn More"}
                            icon={<ChevronRightIcon />}
                            iconRight={true}>
                        </Button>
                    </Link>
                </Box>
                <Flex
                    alignItems="center"
                    justifyContent="center"
                >
                    <Image
                        src={IPMRLaptop}
                        m="auto"
                        display="block"
                        w="100%"
                    />
                </Flex>
            </SimpleGrid>
        </Box>
    )
}

export default LatestProduct