import {
    Container,
    SimpleGrid
} from '@chakra-ui/react';
import SectionHeading from '../cards/SectionHeading';
import ImageWithDesc from '../cards/ImageWithDesc';
import SoftwareImage from '../../assets/images/software.png';
import HardwareImage from '../../assets/images/hardware.png';
import ConnectionImage from '../../assets/images/connection.png';

const WeProvide = () => {

    return (
        <Container maxW="container.lg" p="100px 0">
            <SectionHeading
                heading="We'll do it all!"
                subHeading="our offer"
                details="We here at KunjiSoft will take care of your entire IIoT needs. We provide you with a wide range of services that will help you to monitor your industry and ensure that you have everything under control."
            />
            <SimpleGrid minChildWidth="300px" mt="50px" spacing={8}>
                <ImageWithDesc
                    title="hardware"
                    details="complete hardware installation, maintenance, replacement & improvement updates."
                    image={HardwareImage}
                />
                <ImageWithDesc
                    title="connections"
                    details="proper hardware wiring & connections."
                    image={ConnectionImage}
                />
                <ImageWithDesc
                    title="software"
                    details="software installation and maintenance with regular updates, patches and new features."
                    image={SoftwareImage}
                />
            </SimpleGrid>
        </Container>
    )
}

export default WeProvide