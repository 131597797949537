import {
  useState,
  useEffect
} from "react";
import Layout from "./components/Layout";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./components/sections/Home";
import WeProvide from "./components/sections/WeProvide";
import LatestProduct from "./components/sections/LatestProduct";
import WhatYouDo from "./components/sections/WhatYouDo";
import Industry40 from "./components/sections/Industry40";
import WhyUs from "./components/sections/WhyUs";
import Clients from "./components/sections/Clients";
import Contact from "./components/sections/Contact";
import Loader from "./components/cards/Loader";

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // const body = document.querySelector("body");
    // body.style.overflow = "hidden";
    // body.onload = () => {
    //   setLoading(false);
      // body.style.overflow = "auto";
    // }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <Layout>
      {loading && <Loader />}
      <Navbar />
      <Home />
      <WeProvide />
      <LatestProduct />
      <WhatYouDo />
      <Industry40 />
      <WhyUs />
      <Clients />
      <Contact />
      <Footer />
    </Layout>
  )
}

export default App