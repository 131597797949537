import {
    ChakraProvider,
    Box
} from "@chakra-ui/react";
import Socials from "../cards/Socials";

const Index = ({ children }) => {
    return (
        <ChakraProvider>
            <Box fontFamily={"Poppins"}>
                <Socials />
                {children}
            </Box>
        </ChakraProvider>
    )
}

export default Index