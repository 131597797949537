import {
    Box,
    SimpleGrid,
    Heading,
    Text,
    Image
} from '@chakra-ui/react';
import SectionHeading from '../cards/SectionHeading';
import IndustryImage from "../../assets/images/industry4.png";

const WhatYouDo = () => {
    return (
        <Box
            backgroundColor={"#ececec"}
            p={"50px 10vw"}
            maxW="100vw"
        >
            <SectionHeading
                heading="Why you need to modernise your factory?"
                subHeading="embrace the future"
                details={"The future is here. You need to modernise your factory to make it work for the future. Kunjisoft is here to help you with this."}
            />
            <SimpleGrid minChildWidth={"400px"} spacing={10} mt="50px" maxW="90vw">
                <Box flexDir={"column"} pt="100px">
                    <Heading fontFamily="Poppins" m="0 0 30px 0" maxW="90vw">Gradually learn industry 4.0</Heading>
                    <Text m="0" color="#696969" fontSize="15px" maxW="80%">
                        Industry 4.0 is revolutionizing the way companies manufacture, improve and
                        distribute their products. Manufacturers are integrating new technologies,
                        including Industrial Internet of Things (IIoT), cloud computing and analytics, and AI and
                        machine learning into their production facilities and throughout their operations.
                        <br />
                        <br />
                        Developing smart factories provides an incredible opportunity for the
                        manufacturing industry to enter the fourth industrial revolution . By applying
                        machine learning algorithms, manufacturers can detect errors immediately, rather
                        than at later stages when repair work is more expensive. Industry 4.0 concepts
                        and technologies can be applied across all types of industrial companies.
                    </Text>
                </Box>

                <Image
                    src={IndustryImage}
                    alt="Industry 4.0"
                    maxW="90vw"
                    w="80%"
                />
            </SimpleGrid>
        </Box>
    )
}

export default WhatYouDo