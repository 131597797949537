import {
    Box,
    SimpleGrid
} from '@chakra-ui/react';
import SectionHeading from '../cards/SectionHeading';
import ImageWithDesc from '../cards/ImageWithDesc';
import AnalyzeImage from "../../assets/images/analyze.png";
import SuperviseImage from "../../assets/images/supervise.png";
import OptimizeImage from "../../assets/images/optimize.png";
import EvolveImage from "../../assets/images/evolve.png";

const WhatYouDo = () => {
    return (
        <Box backgroundColor={"#ffffff"} p={"50px 10vw"}>
            <SectionHeading
                heading="What you can do?"
                subHeading="with us"
                details={"Improve your factory's performance by using our services."}
            />
            <SimpleGrid minChildWidth="300px" mt="80px" spacing={8}>
                <ImageWithDesc
                    title="Supervise"
                    details="Analyze your production, your energy consumption and detect any malfunctions."
                    image={SuperviseImage}
                />
                <ImageWithDesc
                    title="Analyze"
                    details="Monitor the solutions for malfunctions and improve your production."
                    image={AnalyzeImage}
                />
                <ImageWithDesc
                    title="Optimize"
                    details="Become a player in your industrial performance by optimizing your key indicators."
                    image={OptimizeImage}
                />
                <ImageWithDesc
                    title="Evolve"
                    details="Evolve your business by using our services."
                    image={EvolveImage}
                />
            </SimpleGrid>
        </Box>
    )
}

export default WhatYouDo