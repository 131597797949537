import {
    Spinner,
    Center
} from '@chakra-ui/react';

const Loader = () => {
    return (
        <Center
            h="100vh"
            w="100%"
            bgColor="#ffffff"
            pos="fixed"
            zIndex="2"
        >
            <Spinner
                color='#ffa500'
                size='xl'
            />
        </Center>
    )
}

export default Loader