import {
    Box,
    Flex,
    Image,
    HStack,
    Text,
    IconButton,
    useDisclosure,
    useColorModeValue,
    Stack,
    useMediaQuery,
    Link
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon, EmailIcon } from '@chakra-ui/icons';
import KunjiSoftLogo from "../../assets/logo/kunjisoft-full-black.png";
import Button from '../buttons/Simple';
import ScrollIntoView from 'react-scroll-into-view';

const Links = [
    {
        title: 'Home',
        goto: "home"
    },
    {
        title: 'Products',
        goto: "products"
    },
    {
        title: 'About',
        goto: "about"
    }
];

const NavLink = ({ children }) => (
    <Text
        px={2}
        py={1}
        rounded={'md'}
        _hover={{
            textDecoration: 'none',
            bg: useColorModeValue('gray.200', 'gray.700'),
            cursor: 'pointer'
        }}
        href={'#'}>
        {children}
    </Text>
);

export default function Navbar() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const isLargerThan600 = useMediaQuery('(min-width: 600px)')[0];
    const isLargerThan900 = useMediaQuery('(min-width: 900px)')[0];
    console.log("🚀 ~ file: index.jsx ~ line 53 ~ Navbar ~ isLargerThan900", isLargerThan900)

    return (
        <>
            <Box px={4} backgroundColor="#ececec" p="1vh 6vh">
                {
                    isLargerThan900 &&
                    <Flex h={8} alignItems={'center'} justifyContent={'space-between'} borderBottom="1px solid #cccccc" p="2px 7px">
                        <Box fontSize={14} gap={4}>
                            <Link fontWeight="500" color="#20252f" _hover={{ textDecoration: 'none', color: "#000" }} href="tel:+922134320900" target={"_blank"} m="0 10px">+92 21 34320900</Link>
                            <Link fontWeight="500" color="#20252f" _hover={{ textDecoration: 'none', color: "#000" }} href="tel:+922134320800" target={"_blank"} m="0 10px">+92 21 34320800</Link>
                            <Link fontWeight="500" color="#20252f" _hover={{ textDecoration: 'none', color: "#000" }} href="mailto:info@kunjisoft.com" target={"_blank"} m="0 10px">info@kunjisoft.com</Link>
                        </Box>
                        <Box fontSize={14} fontWeight="500">
                            <Text>Mon - Sat 10:00AM - 6:00PM, Sunday - CLOSED</Text>
                        </Box>
                    </Flex>
                }
                <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
                    <IconButton
                        size={'md'}
                        icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                        aria-label={'Open Menu'}
                        display={{ md: 'none' }}
                        onClick={isOpen ? onClose : onOpen}
                    />
                    <HStack spacing={8} alignItems={'center'}>
                        <Box>
                            <Image
                                src={KunjiSoftLogo}
                                alt="KunjiSoft Logo"
                                h="40px"
                            />
                        </Box>
                    </HStack>
                    <Flex alignItems={'center'}>
                        <HStack
                            m={"0 20px"}
                            as={'nav'}
                            spacing={4}
                            display={{ base: 'none', md: 'flex' }}>
                            {Links.map((link) => (
                                <ScrollIntoView selector={`#${link.goto}`}>
                                    <NavLink>{link.title}</NavLink>
                                </ScrollIntoView>
                            ))}
                        </HStack>
                        <ScrollIntoView selector="#contact">
                            <Button
                                text={"Contact us"}
                                icon={<EmailIcon />}
                                onlyIcon={!isLargerThan600}>
                            </Button>
                        </ScrollIntoView>
                    </Flex>
                </Flex>

                {isOpen ? (
                    <Box pb={4} display={{ md: 'none' }}>
                        <Stack as={'nav'} spacing={4}>
                            {Links.map((link) => (
                                <ScrollIntoView selector={`#${link.goto}`}>
                                    <NavLink>{link.title}</NavLink>
                                </ScrollIntoView>
                            ))}
                        </Stack>
                    </Box>
                ) : null}
            </Box>
        </>
    );
}