import {
    Flex,
    Link,
    Image
} from '@chakra-ui/react';
import { darken } from '@chakra-ui/theme-tools';
import FacebookIcon from '../../assets/images/socials/facebook-f-brands.svg';
import InstagramIcon from '../../assets/images/socials/instagram-brands.svg';
import LinkedInIcon from '../../assets/images/socials/linkedin-in-brands.svg';

const Socials = () => {
    return (
        <Flex
            position="fixed"
            right="0"
            top="20vh"
            h="100px"
            w="33px"
            flexDir={"column"}
        >
            <Link
                href="https://www.facebook.com/kunjisoft/"
                w="100%"
                h="100%"
                bgColor={"#4267B2"}
                _hover={{ bgColor: darken("#4267B2", "1%") }}
                p="25%"
                borderRadius="5px 0 0 0"
            >
                <Image
                    src={FacebookIcon}
                    alt="facebook icon"
                />
            </Link>
            <Link
                href="https://www.instagram.com/kunjisoft/"
                w="100%"
                h="100%"
                bgGradient="radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%)"
                _hover={{ bgGradient: "radial-gradient(circle farthest-corner at 35% 90%, #584523, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #8a6931, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #26295c, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #1e225c, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #3e1e57, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #391852, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #5a1130, transparent), linear-gradient(#261f66, #6b1650 30%, #741e2f 50%, #723315 70%, #7a5822 100%)" }}
                p="25%"
            >
                <Image
                    src={InstagramIcon}
                    alt="Instgram icon"
                />
            </Link>
            <Link
                href="https://www.linkedin.com/company/kunjisoft/"
                w="100%"
                h="100%"
                bgColor={"#2867B2"}
                _hover={{ bgColor: darken("#4267B2", "1%") }}
                p="25%"
                borderRadius="0 0 0 5px"
            >
                <Image
                    src={LinkedInIcon}
                    alt="Linked in icon"
                />
            </Link>
        </Flex>
    )
}

export default Socials