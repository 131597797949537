import {
    Box,
    SimpleGrid,
    Input,
    Textarea,
    Button,
    FormControl,
    FormLabel,
    FormErrorMessage,
    useToast
} from '@chakra-ui/react';
import {
    useState
} from "react";
import SectionHeading from '../cards/SectionHeading';
import { ChevronRightIcon } from "@chakra-ui/icons";
import { push, write as set } from "../firebase/api";
import { increment } from "@firebase/database";

const Contact = () => {

    const toast = useToast();
    const [data, setData] = useState({
        name: null,
        email: null,
        tel: null,
        subject: null,
        message: null
    })
    const [errors, setErrors] = useState({
        name: null,
        email: null,
        tel: null,
        subject: null,
        message: null
    })
    const [mainError, setMainError] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    const inputChangeHandler = e => {
        const id = e.target.id;
        const value = e.target.value;

        setData({
            ...data,
            [id]: value
        })
    }

    const validateName = () => {
        if (data.name === null || data.name === '') {
            setErrors({
                ...errors,
                name: 'Name is required'
            })
        }
        else if (data.name.length > 15) {
            setErrors({
                ...errors,
                name: 'Name must be less than 15 characters long'
            })
        }
        else {
            setErrors({
                ...errors,
                name: null
            })
        }
    }

    const validateEmail = () => {
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (data.email === null || data.email === '') {
            setErrors({
                ...errors,
                email: 'Email is required'
            })
        }
        else if (!emailRegex.test(data.email)) {
            setErrors({
                ...errors,
                email: 'Email is not valid'
            })
        } else {
            setErrors({
                ...errors,
                email: null
            })
        }
    }

    const validateTel = () => {
        const telRegex = /^[0-9]{11}$/;
        if (data.tel === null || data.tel === '') {
            setErrors({
                ...errors,
                tel: 'Phone number is required'
            })
        }
        else if (!telRegex.test(data.tel)) {
            setErrors({
                ...errors,
                tel: 'Phone number is not valid'
            })
        } else {
            setErrors({
                ...errors,
                tel: null
            })
        }
    }

    const validateSubject = () => {
        if (data.subject === null || data.subject === '') {
            setErrors({
                ...errors,
                subject: 'Subject is required'
            })
        }
        else if (data.subject.length > 21) {
            setErrors({
                ...errors,
                subject: 'Subject must be less than 21 characters long'
            })
        }
        else {
            setErrors({
                ...errors,
                subject: null
            })
        }
    }

    const validateMessage = () => {
        if (data.message === null || data.message === '') {
            setErrors({
                ...errors,
                message: 'Message is required'
            })
        }
        else if (data.message.length > 200) {
            setErrors({
                ...errors,
                message: 'Message must be less than 200 characters long'
            })
        }
        else {
            setErrors({
                ...errors,
                message: null
            })
        }
    }

    const submitHandler = async () => {
        setSubmitting(true);
        const hasErrors = !Object.values(errors).every(error => error === null);
        const allEmpty = Object.values(data).every(value => value === null);
        if (allEmpty) {
            setMainError('All fields are required');
            setSubmitting(false);
        }
        else if (hasErrors) {
            setMainError('Please resolve all errors before submitting');
            setSubmitting(false);
        } else {
            setMainError(null);
            push('contacts/queries', data)
                .then(() => {
                    set('contacts/total', increment(1))
                        .then(() => {
                            toast({
                                title: "Your query has been submitted",
                                status: "success",
                                isClosable: true,
                                onCloseComplete: () => {
                                    // reset form
                                    setData({
                                        name: "",
                                        email: "",
                                        tel: "",
                                        subject: "",
                                        message: ""
                                    })
                                    setSubmitting(false);
                                }
                            })
                        })
                        .catch(err => {
                            toast({
                                title: err.toString(),
                                status: "error",
                                isClosable: true,
                                onCloseComplete: () => {
                                    setSubmitting(false);
                                }
                            })
                        })
                })
                .catch(err => {
                    toast({
                        title: err.toString(),
                        status: "error",
                        isClosable: true,
                        onCloseComplete: () => {
                            setSubmitting(false);
                        }
                    })
                })
        }
    }

    return (
        <Box
            id="contact"
            backgroundColor={"#ffffff"}
            p={"50px 10vw"}
            maxW="100vw"
        >
            <SectionHeading
                heading="Get In Touch"
                subHeading="contact us"
                details="Feel free to leave us a message if you have any queries, we love to answer your questions."
            />
            <SimpleGrid minChildWidth={"400px"} spacing={10} mt="50px" maxW="90vw">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3619.880027803073!2d67.0834301!3d24.867946799999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33f8b3c788dcd%3A0xde8bbdfdbf182979!2sKunjiSoft%20Technologies%20(Pvt)%20Ltd.!5e0!3m2!1sen!2s!4v1653655612810!5m2!1sen!2s"
                    height="100%"
                    width="90%"
                    style={{ maxWidth: "80vw", borderRadius: "10px", boxShadow: "0px 0px 10px #cccccc4c", border: "1px solid #cccccc2b" }}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                    title="KunjiSoft Technologies"
                />
                <Box
                    flexDir={"column"}
                    borderRadius="10px"
                    maxW="80vw"
                    backgroundColor={"#ececec"}
                    border="1px solid #cccccc2b"
                    boxShadow={"0px 0px 10px #cccccc4c"}
                    p="2vw"
                >
                    <Box>
                        <FormControl isDisabled={submitting} mb="10px" isInvalid={errors.name !== null}>
                            <FormLabel htmlFor='name'>Full Name*</FormLabel>
                            <Input
                                id='name'
                                type='Text'
                                placeholder='Your name'
                                borderColor={'#718096a9'}
                                _hover={{ borderColor: '#ffa500' }}
                                _focus={{ borderColor: '#ffa500', boxShadow: '0 0 0 1px #ffa500' }}
                                value={data.name}
                                onChange={inputChangeHandler}
                                onBlur={validateName}
                            />
                            <FormErrorMessage>{errors.name}</FormErrorMessage>
                        </FormControl>
                        <FormControl isDisabled={submitting} mb="10px" isInvalid={errors.email !== null}>
                            <FormLabel htmlFor='email'>Email address*</FormLabel>
                            <Input
                                id='email'
                                type='email'
                                placeholder='Your email e.g (info@kunjisoft.com)'
                                borderColor={'#718096a9'}
                                _hover={{ borderColor: '#ffa500' }}
                                _focus={{ borderColor: '#ffa500', boxShadow: '0 0 0 1px #ffa500' }}
                                value={data.email}
                                onChange={inputChangeHandler}
                                onBlur={validateEmail}
                            />
                            <FormErrorMessage>{errors.email}</FormErrorMessage>
                        </FormControl>
                        <FormControl isDisabled={submitting} mb="10px" isInvalid={errors.tel !== null}>
                            <FormLabel htmlFor='tel'>Phone Number*</FormLabel>
                            <Input
                                id='tel'
                                type='tel'
                                placeholder='Your phone number e.g (03123456789)'
                                borderColor={'#718096a9'}
                                _hover={{ borderColor: '#ffa500' }}
                                _focus={{ borderColor: '#ffa500', boxShadow: '0 0 0 1px #ffa500' }}
                                value={data.tel}
                                onChange={inputChangeHandler}
                                onBlur={validateTel}
                            />
                            <FormErrorMessage>{errors.tel}</FormErrorMessage>
                        </FormControl>
                        <FormControl isDisabled={submitting} mb="10px" isInvalid={errors.subject !== null}>
                            <FormLabel htmlFor='subject'>Subject*</FormLabel>
                            <Input
                                id='subject'
                                type='text'
                                placeholder='Message subject'
                                borderColor={'#718096a9'}
                                _hover={{ borderColor: '#ffa500' }}
                                _focus={{ borderColor: '#ffa500', boxShadow: '0 0 0 1px #ffa500' }}
                                value={data.subject}
                                onChange={inputChangeHandler}
                                onBlur={validateSubject}
                            />
                            <FormErrorMessage>{errors.subject}</FormErrorMessage>
                        </FormControl>
                        <FormControl isDisabled={submitting} mb="10px" isInvalid={errors.message !== null}>
                            <FormLabel htmlFor='subject'>Message*</FormLabel>
                            <Textarea
                                id='message'
                                type='text'
                                placeholder='Your message'
                                borderColor={'#718096a9'}
                                _hover={{ borderColor: '#ffa500' }}
                                _focus={{ borderColor: '#ffa500', boxShadow: '0 0 0 1px #ffa500' }}
                                value={data.message}
                                onChange={inputChangeHandler}
                                onBlur={validateMessage}
                            />
                            <FormErrorMessage>{errors.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl isDisabled={submitting} mt="20px" isInvalid={mainError !== null}>
                            <Button
                                variant={'solid'}
                                backgroundColor={"#ffa500"}
                                _hover={{ bg: '#a18417' }}
                                _active={{ bg: '#a18417' }}
                                color="#fff"
                                _focus={{ boxShadow: 'none' }}
                                size={'sm'}
                                mr={4}
                                p={5}
                                rightIcon={<ChevronRightIcon />}
                                onClick={submitHandler}
                                isLoading={submitting}
                            >
                                Submit
                            </Button>
                            <FormErrorMessage>{mainError}</FormErrorMessage>
                        </FormControl>
                    </Box>
                </Box>
            </SimpleGrid>
        </Box>
    )
}

export default Contact