import {
    Box,
    Image,
    Text,
    Heading,
    Flex
} from '@chakra-ui/react';

const ImageWithDesc = ({
    image,
    title,
    details
}) => {
    return (
        <Box>
            <Flex
                w="250px"
                h="180px"
                alignItems={"center"}
                justifyContent={"center"}
                m="auto"
            >
                <Image
                    src={image}
                    alt={title}
                    maxW="100%"
                    maxH="100%"
                />
            </Flex>
            <Box textAlign="center" mt="50px">
                <Heading
                    fontFamily="Poppins"
                    textTransform="capitalize"
                    fontSize="20px"
                    fontWeight="600"
                >{title}</Heading>
                <Text color="#696969" fontSize="13px" w="80%" display="block" m="10px auto auto auto">{details}</Text>
            </Box>
        </Box>
    )
}

export default ImageWithDesc