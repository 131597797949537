import {
    Box,
    Image,
    SimpleGrid
} from '@chakra-ui/react';
import SectionHeading from '../cards/SectionHeading';
import PlastisolImage from "../../assets/images/clients/plastisol.png";
import MehranImage from "../../assets/images/clients/mehran.png";
import MaltaImage from "../../assets/images/clients/malta.jpeg";
// import Marquee from "react-fast-marquee";

const Clients = () => {
    return (
        <Box
            backgroundColor={"#ececec"}
            p={"50px 10vw"}
            maxW="100vw"
        >
            <SectionHeading
                heading="They Trusted Us"
                subHeading="our clients"
                details=""
            />
            <Box mt="50px" maxW="90vw">
                <SimpleGrid
                minChildWidth="200px"
                // gradient={false}
                // pauseOnHover={true}
                >
                    <Image
                        src={PlastisolImage}
                        alt="plastisol"
                        maxW="300px"
                        m="auto"
                        display="block"
                    />
                    <Image
                        src={MehranImage}
                        alt="mehran brush"
                        maxW="300px"
                        maxH="100px"
                        m="auto"
                        display="block"
                    />
                    <Image
                        src={MaltaImage}
                        alt="malta auto industries"
                        maxW="300px"
                        maxH="100px"
                        m="auto"
                        display="block"
                    />
                </SimpleGrid>
            </Box>
        </Box>
    )
}

export default Clients