import {
    Box,
    SimpleGrid,
    Heading,
    Text,
    Image
} from '@chakra-ui/react';
import SectionHeading from '../cards/SectionHeading';
import AboutImage from "../../assets/images/about.png";

const WhyUs = () => {
    return (
        <Box
            id="about"
            backgroundColor={"#ffffff"}
            p={"50px 10vw"}
            maxW="100vw"
        >
            <SectionHeading
                heading="A Little About Us"
                subHeading="why us"
                details="get to know a little about KunjiSoft Technologies"
            />
            <SimpleGrid minChildWidth={"400px"} spacing={10} mt="50px" maxW="90vw">
                <Image
                    src={AboutImage}
                    alt="About"
                    maxW="90vw"
                    w="80%"
                    m="auto 0"
                />
                <Box flexDir={"column"} pt="100px">
                    <Heading fontFamily="Poppins" m="0 0 5px 0" maxW="90vw">Backed by the industry experts</Heading>
                    <Heading fontFamily="Poppins" m="0 0 30px 0" maxW="90vw" fontSize="20px" fontWeight="500" color="#696969">– Kunji Industrial Solutions</Heading>
                    <Text m="0" color="#696969" fontSize="15px" maxW="80%">
                        KunjiSoft Technologies is a leading IIoT solutions/products provider in the Pakistan.
                        <br />
                        <br />
                        At KunjiSoft we love to build and refine technologies that improve lives while developing great relationships along the way.
                        <br />
                        <br />
                        We are a team of highly skilled and dedicated engineers who are passionate about building great products.
                        <br />
                        <br />
                        KunjiSoft Technologies is backed by the <strong>Kunji Industrial Solutions</strong> who have been in the service industry for over 30 years.
                    </Text>
                </Box>
            </SimpleGrid>
        </Box>
    )
}

export default WhyUs