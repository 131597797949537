import {
    Box,
    SimpleGrid,
    Heading,
    List,
    ListItem,
    Link,
    Image,
    Center,
    Button,
    GridItem,
    Flex,
    Text
} from '@chakra-ui/react';
import { PhoneIcon, EmailIcon } from '@chakra-ui/icons';
import KunjisoftImage from "../../assets/logo/kunjisoft-full-black.png";
import CallUsBgImage from "../../assets/images/callus-bg.svg";
import ScrollIntoView from 'react-scroll-into-view';

const Index = () => {
    return (
        <Box bgColor="#ececec" mt="200px">
            <Flex
                justify={'center'}
            >
                <Box
                    backgroundColor="#ffa500"
                    width="70vw"
                    transform="translateY(-100px)"
                    borderRadius="30px"
                    boxShadow="0 0 20px #cccccc92"
                >
                    <SimpleGrid
                        bgImage={CallUsBgImage}
                        bgSize="cover"
                        bgRepeat="no-repeat"
                        minChildWidth="200px"
                        color="#fff"
                        p="10vh 5vw"
                        borderRadius="30px"
                    >
                        <GridItem
                            colSpan={2}
                        >
                            <Center
                            >
                                <Heading fontFamily={"Poppins"}>
                                    Do you want to request a demo or just have a question? Give us a call.
                                </Heading>
                            </Center>
                        </GridItem>
                        <Center
                            flexDir="column"
                        >
                            <Link
                                // mt="50px"
                                href={"tel:+922134320900"}
                                w="fit-content"
                                target="_blank"
                                _hover={{ textDecoration: 'none' }}>
                                <Button
                                    variant={'solid'}
                                    backgroundColor={"#fff"}
                                    _hover={{ bg: '#ccc' }}
                                    _active={{ bg: '#ccc' }}
                                    color="#ffa500"
                                    _focus={{ boxShadow: 'none' }}
                                    size={'sm'}
                                    mr={4}
                                    p={5}
                                    leftIcon={<PhoneIcon />}>
                                    +92 21 34320900
                                </Button>
                            </Link>
                            <Link
                                mt="10px"
                                href={"tel:+923412636264"}
                                w="fit-content"
                                target="_blank"
                                _hover={{ textDecoration: 'none' }}>
                                <Button
                                    variant={'solid'}
                                    backgroundColor={"#fff"}
                                    _hover={{ bg: '#ccc' }}
                                    _active={{ bg: '#ccc' }}
                                    color="#ffa500"
                                    _focus={{ boxShadow: 'none' }}
                                    size={'sm'}
                                    mr={4}
                                    p={5}
                                    leftIcon={<PhoneIcon />}>
                                    +92 341 2636264
                                </Button>
                            </Link>
                        </Center>
                    </SimpleGrid>
                </Box>
            </Flex>
            <SimpleGrid minChildWidth="200px" gap={10} p="5vh 0" m="0 10vw">
                <Box>
                    <Heading fontFamily="Poppins" fontSize="18px" mb="15px">Contact us</Heading>
                    <List fontSize="13px" spacing={2}>
                        <ListItem>
                            <Link color="#ffa500" _hover={{ textDecoration: 'none', color: "#91740c" }} href="mailto:info@kunjisoft.com" target={"_blank"}>
                                <EmailIcon mr="3px" /> info@kunjisoft.com
                            </Link>
                        </ListItem>
                        <ListItem>
                            <Link color="#ffa500" _hover={{ textDecoration: 'none', color: "#91740c" }} href="tel:+922134320800" target={"_blank"}>
                                <PhoneIcon mr="3px" /> +92 21 34320800
                            </Link>
                        </ListItem>
                        <ListItem>
                            <Link color="#ffa500" _hover={{ textDecoration: 'none', color: "#91740c" }} href="tel:+922134320900" target={"_blank"}>
                                <PhoneIcon mr="3px" /> +92 21 34320900
                            </Link>
                        </ListItem>
                        <ListItem>
                            <Link color="#ffa500" _hover={{ textDecoration: 'none', color: "#91740c" }} href="tel:+923412636264" target={"_blank"}>
                                <PhoneIcon mr="3px" /> +92 341 2636264
                            </Link>
                        </ListItem>
                    </List>
                </Box>
                <Box>
                    <Heading fontFamily="Poppins" fontSize="18px" mb="15px">Website</Heading>
                    <List fontSize="13px" spacing={2}>
                        <ListItem>
                            <ScrollIntoView selector="#products">
                                <Text fontWeight="500" color="#696969" _hover={{ textDecoration: 'none', color: "#464646", cursor: 'pointer' }}>
                                    Products
                                </Text>
                            </ScrollIntoView>
                        </ListItem>
                        <ListItem>
                            <ScrollIntoView selector="#about">
                                <Text fontWeight="500" color="#696969" _hover={{ textDecoration: 'none', color: "#464646", cursor: 'pointer' }}>
                                    About
                                </Text>
                            </ScrollIntoView>
                        </ListItem>
                        <ListItem>
                            <ScrollIntoView selector="#contact">
                                <Text fontWeight="500" color="#696969" _hover={{ textDecoration: 'none', color: "#464646", cursor: 'pointer' }}>
                                    Contact
                                </Text>
                            </ScrollIntoView>
                        </ListItem>
                        {/* <ListItem>
                            <Link fontWeight="500" color="#696969" _hover={{ textDecoration: 'none', color: "#464646" }} target={"_blank"}>
                                Privacy Policy
                            </Link>
                        </ListItem>
                        <ListItem>
                            <Link fontWeight="500" color="#696969" _hover={{ textDecoration: 'none', color: "#464646" }} target={"_blank"}>
                                Terms & Condition
                            </Link>
                        </ListItem> */}
                    </List>
                </Box>
                <Box>
                    <Heading fontFamily="Poppins" fontSize="18px" mb="15px">Social</Heading>
                    <List fontSize="13px" spacing={2}>
                        <ListItem>
                            <Link href="https://linkedin.com/company/kunjisoft" fontWeight="500" color="#696969" _hover={{ textDecoration: 'none', color: "#464646" }} target={"_blank"}>
                                LinkedIn
                            </Link>
                        </ListItem>
                        <ListItem>
                            <Link href="https://www.facebook.com/kunjisoft" fontWeight="500" color="#696969" _hover={{ textDecoration: 'none', color: "#464646" }} target={"_blank"}>
                                Facebook
                            </Link>
                        </ListItem>
                        <ListItem>
                            <Link href="https://www.instagram.com/kunjisoft/" fontWeight="500" color="#696969" _hover={{ textDecoration: 'none', color: "#464646" }} target={"_blank"}>
                                Instagram
                            </Link>
                        </ListItem>
                    </List>
                </Box>
                <Box>
                    <Image
                        mb="20px"
                        maxH={"50px"}
                        src={KunjisoftImage}
                    />
                    <List maxW="300px" fontSize="13px" spacing={2}>
                        <ListItem>
                            <Link fontWeight="500" color="#696969" _hover={{ textDecoration: 'none', color: "#464646" }} href="https://g.page/kunjisoft" target={"_blank"}>
                                Office #304 , Amber Estate Extention Building, Block 7 & 8, Shahrah-e-Faisal, Kathiawar Society Block A Bangalore Town, Karachi, 75400
                            </Link>
                        </ListItem>
                    </List>
                </Box>
            </SimpleGrid>
        </Box>
    )
}

export default Index