import {
    Heading,
    Text,
    Box
} from '@chakra-ui/react';

const SectionHeading = ({
    heading,
    subHeading,
    details
}) => {
    return (
        <Box m="30px auto" maxW="600px" display="block">
            <Text
                textTransform="uppercase"
                color={"#ffa500"}
                textAlign="center"
                fontWeight="600"
            >{subHeading}</Text>
            <Heading
                fontFamily="Poppins"
                textAlign="center"
                fontSize="40px"
                mb="10px"
            >{heading}</Heading>
            <Text
                color="#696969"
                textAlign="center"
                fontSize={"13px"}
            >
                {details || ""}
            </Text>
        </Box>
    )
}

export default SectionHeading