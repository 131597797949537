import { database as db } from './index';
import { ref, child, get, set, update as firebaseUpdate, push } from "@firebase/database";

// get data once from firebase RTDB
const once = (path) => {
    return new Promise((resolve, reject) => {
        // check for missing arguments
        if (!path) reject("Missing arguments");

        const dbRef = ref(db);
        // get from given path
        get(child(dbRef, path)).then((snapshot) => {
            // send recieved snapshot to resolve
            resolve(snapshot);
        }).catch((error) => {
            // raise exception
            reject(error);
        });
    });
}

const write = (path, data) => {
    return new Promise((resolve, reject) => {
        // check for missing arguments
        if (!path || !data) reject("Missing arguments");

        // set given data to given path
        set(ref(db, path), data)
            .then(() => {
                // success
                resolve(true);
            })
            .catch((error) => {
                // raise exception
                reject(error);
            });
    });
}

const update = (path, data) => {
    return new Promise((resolve, reject) => {
        // check for missing arguments
        if (!path || !data) reject("Missing arguments");

        // update given data to given path
        firebaseUpdate(ref(db, path), data)
            .then(() => {
                // success
                resolve(true);
            })
            .catch((error) => {
                // raise exception
                reject(error);
            });
    });
}

const pushAPI = (path, data) => {
    return new Promise((resolve, reject) => {
        // check for missing arguments
        if (!path || !data) reject("Missing arguments");

        // push given data to given path
        push(ref(db, path), data)
            .then(() => {
                // success
                resolve(true);
            })
            .catch((error) => {
                // raise exception
                reject(error);
            });
    });
}

export { once as get, write, update, pushAPI as push };