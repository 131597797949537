import {
    Box,
    Heading,
    Text,
    Link,
    Image
} from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import Button from '../buttons/Simple';

const ProductCard = ({
    title,
    details,
    link,
    comingSoon = false,
    image
}) => {
    return (
        <Box bgColor={"#ffffff"} boxShadow="0 0 20px #cccccc92" borderRadius="20px" p="25px" minH="300px">
            <Text color={"#ffa500"} fontWeight="600" fontSize="10px">HARDWARE . CONNECTION . SOFTWARE</Text>
            <Heading fontSize="20px" m="15px 0" color="#4c4c4c" fontFamily="Poppins">{title}</Heading>
            <Text mb="20px" color="#838383" fontSize="12px">{details}</Text>
            <Link
                href={link}
                w="fit-content"
                target="_blank"
                _hover={{ textDecoration: 'none' }}>
                <Button
                    text={comingSoon ? "Coming Soon" : "Learn More"}
                    icon={<ChevronRightIcon />}
                    iconRight={true}
                    disabled={comingSoon}>
                </Button>
            </Link>
            <Image
                src={image}
                alt={"product image"}
                h="150px"
                float="right"
                mt="20px"
            />
        </Box>
    )
}

export default ProductCard