import {
    useMediaQuery,
    Image,
    Container,
    Heading,
    Center,
    Flex,
    Text,
    Box,
    SimpleGrid
} from '@chakra-ui/react';
import HeroImage from '../../assets/images/hero.png';
import ProductCard from '../cards/ProductCard';
import MeterImage from "../../assets/images/meter.png";
import MonitorImage from "../../assets/images/monitor.png";
import heroBG from "../../assets/images/hero-bg.svg";

const Home = () => {

    const [isLargerThan1300] = useMediaQuery('(min-width: 1300px)')

    return (
        <>
            <Flex id="home" minH="fit-content" bgImage={heroBG} bgSize="cover" bgRepeat="no-repeat" justifyContent={"center"} flexDirection={isLargerThan1300 ? "row" : "column"} p={"70px 0 200px 0"}>
                <Center height='100%'>
                    <Container textAlign={isLargerThan1300 ? "left" : "center"} m="70px 0">
                        <Heading fontFamily={"Poppins"} fontWeight={700} fontSize={42}>IIoT products &<br />solutions for your industry</Heading>
                        <Text mt="20px" color="#696969">KunjiSoft offers innovative IIoT solutions intented for all types of industries.</Text>
                    </Container>
                </Center>
                <Center height='100%'>
                    <Container maxW="container.md">
                        <Image
                            src={HeroImage}
                            alt="hero image"
                        />
                    </Container>
                </Center>
            </Flex>
            <Box p={isLargerThan1300 ? "50px 10vw" : "50px 10px"} mt="-200px" id="products">
                <Text fontWeight="bold" textAlign="center" fontSize="20px">Choose the right product that interests you</Text>
                <SimpleGrid columns={2} spacing={8} mt="50px" minChildWidth={"350px"}>
                    <ProductCard
                        title={"Production & Electricity Consumption Monitoring"}
                        details={"Realtime production & electricity consumption monitoring and reporting."}
                        link={"https://industrialpmr.com/"}
                        image={MonitorImage}
                    />
                    <ProductCard
                        title={"Detailed Energy Monitoring"}
                        details={"Realtime details energy consumption monitoring and reporting intented for technical teams."}
                        link={null}
                        comingSoon={true}
                        image={MeterImage}
                    />
                </SimpleGrid>
            </Box>
        </>
    )
}

export default Home