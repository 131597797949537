import {
    Button,
    IconButton
} from '@chakra-ui/react';

const Simple = ({
    text,
    disabled = false,
    icon,
    onlyIcon,
    onClick,
    iconRight = false
}) => {
    return (
        onlyIcon ? (
            <IconButton
                size={'md'}
                backgroundColor={"#ffa500"}
                _hover={{ bg: '#a18417' }}
                _active={{ bg: '#a18417' }}
                color="#fff"
                _focus={{ boxShadow: 'none' }}
                disabled={disabled}
                icon={icon}
                aria-label={text}
                display={{ md: 'none' }}
                onClick={onClick}
            />
        ) : (
            <Button
                variant={'solid'}
                backgroundColor={"#ffa500"}
                _hover={{ bg: '#a18417' }}
                _active={{ bg: '#a18417' }}
                color="#fff"
                _focus={{ boxShadow: 'none' }}
                size={'sm'}
                mr={4}
                p={5}
                disabled={disabled}
                rightIcon={iconRight && icon}
                leftIcon={!iconRight && icon}
                onClick={onClick}>
                {text}
            </Button>
        )
    )
}

export default Simple